export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    UPDATE_REQUEST: 'USERS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'USERS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'USERS_UPDATE_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',

    PROFILE_REQUEST: 'PROFILE_REQUEST',
    PROFILE_SUCCESS: 'PROFILE_SUCCESS',
    PROFILE_FAILURE: 'PROFILE_FAILURE',
    
    LOGOUT: 'USERS_LOGOUT',

    ADD_BANK_REQUEST: "ADD_BANK_REQUEST",
    ADD_BANK_SUCCESS: "ADD_BANK_SUCCESS",
    ADD_BANK_FAILURE: "ADD_BANK_FAILURE",

    UPDATE_BANK_REQUEST: "UPDATE_BANK_REQUEST",
    UPDATE_BANK_SUCCESS: "UPDATE_BANK_SUCCESS",
    UPDATE_BANK_FAILURE: "UPDATE_BANK_FAILURE",

    USER_EDIT_ANALYTICS_MODAL: "USER_EDIT_ANALYTICS_MODAL",

    BANK_ACCOUNT_LIST_REQUEST: "BANK_ACCOUNT_LIST_REQUEST",
    BANK_ACCOUNT_LIST_SUCCESS: "BANK_ACCOUNT_LIST_SUCCESS",
    BANK_ACCOUNT_LIST_FAILURE: "BANK_ACCOUNT_LIST_FAILURE",

    BANK_ACCOUNT_SET_DEFAULT_REQUEST : "BANK_ACCOUNT_SET_DEFAULT_REQUEST",
    BANK_ACCOUNT_SET_DEFAULT_SUCCESS : "BANK_ACCOUNT_SET_DEFAULT_SUCCESS",
    BANK_ACCOUNT_SET_DEFAULT_FAILURE : "BANK_ACCOUNT_SET_DEFAULT_FAILURE",

    BANK_ACCOUNT_DELETE_REQUEST : "BANK_ACCOUNT_DELETE_REQUEST",
    BANK_ACCOUNT_DELETE_SUCCESS : "BANK_ACCOUNT_DELETE_SUCCESS",
    BANK_ACCOUNT_DELETE_FAILURE : "BANK_ACCOUNT_DELETE_FAILURE",

    OPEN_ADD_BANK_ACCOUNT_COMPONENT : "OPEN_ADD_BANK_ACCOUNT_COMPONENT",
    HIDE_ADD_BANK_ACCOUNT_COMPONENT : "HIDE_ADD_BANK_ACCOUNT_COMPONENT",

    UPDATE_BANK_COMPONENT : "UPDATE_BANK_COMPONENT",

    HIDE_UPDATE_BANK_ACCOUNT_COMPONENT : "HIDE_UPDATE_BANK_ACCOUNT_COMPONENT",

    RESET_USER_SETTINGS : "RESET_USER_SETTINGS",

    
	BANK_ERRORS : {
		ADDRESS_ERROR : ['invalid_address_city_state_postal_code','invalid_street_address','invalid_value_other','verification_failed_keyed_match','verification_failed_name_match','verification_failed_other', 'custom_error'],

		DOCUMENT_ERROR : ['verification_document_address_mismatch','verification_document_address_missing','verification_document_corrupt','verification_document_country_not_supported','verification_document_dob_mismatch','verification_document_duplicate_type','verification_document_expired','verification_document_failed_copy','verification_document_failed_greyscale','verification_document_failed_other','verification_document_failed_test_mode','verification_document_fraudulent','verification_document_id_number_mismatch','verification_document_id_number_missing','verification_document_incomplete','verification_document_invalid','verification_document_issue_or_expiry_date_missing','verification_document_manipulated','verification_document_missing_back','verification_document_missing_front','verification_document_name_mismatch','verification_document_name_missing','verification_document_nationality_mismatch','verification_document_not_readable','verification_document_not_signed','verification_document_not_uploaded','verification_document_photo_mismatch','verification_document_too_large','verification_document_type_not_supported','verification_failed_address_match','verification_failed_document_match','verification_failed_keyed_identity','verification_failed_keyed_match','verification_failed_name_match','verification_failed_other', 'custom_error'],

		SSN_ERROR : ['verification_failed_id_number_match','verification_failed_keyed_match','verification_failed_other','verification_failed_tax_id_match','verification_failed_tax_id_not_issued','custom_error'],

		NAME_ERROR : ['verification_failed_keyed_identity','verification_failed_keyed_match','verification_failed_other', 'custom_error'],

		DOB_ERROR : ['verification_failed_keyed_identity','verification_failed_keyed_match','verification_failed_other', 'custom_error'],

		OTHER_ERROR : ['verification_failed_business_iec_number','verification_missing_owners','verification_missing_executives','verification_requires_additional_memorandum_of_associations','custom_error']
	},

	VERIFIED : "verified",
    FORGOT_PASSWORD_REQUEST : "FORGOT_PASSWORD_REQUEST",
    FORGOT_PASSWORD_SUCCESS : "FORGOT_PASSWORD_SUCCESS",
    FORGOT_PASSWORD_FAILURE : "FORGOT_PASSWORD_FAILURE",
    UPDATE_TOTAL_INSTANT_AVAILABLE : "UPDATE_TOTAL_INSTANT_AVAILABLE",
};



export const industryOptions = [
    { value: "", label: "Select an industry", isdisabled: true },
    {
        code: "ac_refrigeration_repair",
        value: "7623",
        label: "A/C Refrigeration Repair",
    },
    {
        code: "accounting_bookkeeping_services",
        value: "8931",
        label: "Accounting/Bookkeeping Services",
    },
    {
        code: "advertising_services",
        value: "7311",
        label: "Advertising Services",
    },
    {
        code: "agricultural_cooperative",
        value: "0763",
        label: "Agricultural Cooperative",
    },
    {
        code: "airlines_air_carriers",
        value: "4511",
        label: "Airlines Air Carriers",
    },
    {
        code: "airports_flying_fields",
        value: "4582",
        label: "Airports Flying Fields",
    },
    {
        code: "ambulance_services",
        value: "4119",
        label: "Ambulance Services",
    },
    {
        code: "amusement_parks_carnivals",
        value: "7996",
        label: "Amusement Parks/Carnivals",
    },
    {
        code: "antique_reproductions",
        value: "5937",
        label: "Antique Reproductions",
    },
    {
        code: "antique_shops",
        value: "5932",
        label: "Antique Shops",
    },
    {
        code: "aquariums",
        value: "7998",
        label: "Aquariums",
    },
    {
        code: "architectural_surveying_services",
        value: "8911",
        label: "Architectural/Surveying Services",
    },
    {
        code: "art_dealers_and_galleries",
        value: "5971",
        label: "Art Dealers and Galleries",
    },
    {
        code: "artists_supply_and_craft_shops",
        value: "5970",
        label: "Artists Supply and Craft Shops",
    },
    {
        code: "auto_body_repair_shops",
        value: "7531",
        label: "Auto Body Repair Shops",
    },
    {
        code: "auto_paint_shops",
        value: "7535",
        label: "Auto Paint Shops",
    },
    {
        code: "auto_service_shops",
        value: "7538",
        label: "Auto Service Shops",
    },
    {
        code: "auto_and_home_supply_stores",
        value: "5531",
        label: "Auto and Home Supply Stores",
    },
    {
        code: "automated_cash_disburse",
        value: "6011",
        label: "Automated Cash Disburse",
    },
    {
        code: "automated_fuel_dispensers",
        value: "5542",
        label: "Automated Fuel Dispensers",
    },
    {
        code: "automobile_associations",
        value: "8675",
        label: "Automobile Associations",
    },
    {
        code: "automotive_parts_and_accessories_stores",
        value: "5533",
        label: "Automotive Parts and Accessories Stores",
    },
    {
        code: "automotive_tire_stores",
        value: "5532",
        label: "Automotive Tire Stores",
    },
    {
        code: "bail_and_bond_payments",
        value: "9223",
        label: "Bail and Bond Payments (payment to the surety for the bond not the actual bond paid to the government agency)",
    },
    {
        code: "bakeries",
        value: "5462",
        label: "Bakeries",
    },
    {
        code: "bands_orchestras",
        value: "7929",
        label: "Bands Orchestras",
    },
    {
        code: "barber_and_beauty_shops",
        value: "7230",
        label: "Barber and Beauty Shops",
    },
    {
        code: "betting_casino_gambling",
        value: "7995",
        label: "Betting/Casino Gambling",
    },
    {
        code: "bicycle_shops",
        value: "5940",
        label: "Bicycle Shops",
    },
    {
        code: "billiard_pool_establishments",
        value: "7932",
        label: "Billiard/Pool Establishments",
    },
    {
        code: "boat_dealers",
        value: "5551",
        label: "Boat Dealers",
    },
    {
        code: "boat_rentals_and_leases",
        value: "4457",
        label: "Boat Rentals and Leases",
    },
    {
        code: "book_stores",
        value: "5942",
        label: "Book Stores",
    },
    {
        code: "books_periodicals_and_newspapers",
        value: "5192",
        label: "Books Periodicals and Newspapers",
    },
    {
        code: "bowling_alleys",
        value: "7933",
        label: "Bowling Alleys",
    },
    {
        code: "bus_lines",
        value: "4131",
        label: "Bus Lines",
    },
    {
        code: "business_secretarial_schools",
        value: "8244",
        label: "Business/Secretarial Schools",
    },
    {
        code: "buying_shopping_services",
        value: "7278",
        label: "Buying/Shopping Services",
    },
    {
        code: "cable_satellite_and_other_pay_television_and_radio",
        value: "4899",
        label: "Cable Satellite and Other Pay Television and Radio",
    },
    {
        code: "camera_and_photographic_supply_stores",
        value: "5946",
        label: "Camera and Photographic Supply Stores",
    },
    {
        code: "candy_nut_and_confectionery_stores",
        value: "5441",
        label: "Candy Nut and Confectionery Stores",
    },
    {
        code: "car_rental_agencies",
        value: "7512",
        label: "Car Rental Agencies",
    },
    {
        code: "car_washes",
        value: "7542",
        label: "Car Washes",
    },
    {
        code: "car_and_truck_dealers_new_used",
        value: "5511",
        label: "Car and Truck Dealers (New &amp; Used) Sales Service Repairs Parts and Leasing",
    },
    {
        code: "car_and_truck_dealers_used_only",
        value: "5521",
        label: "Car and Truck Dealers (Used Only) Sales Service Repairs Parts and Leasing",
    },
    {
        code: "carpentry_services",
        value: "1750",
        label: "Carpentry Services",
    },
    {
        code: "carpet_upholstery_cleaning",
        value: "7217",
        label: "Carpet/Upholstery Cleaning",
    },
    {
        code: "caterers",
        value: "5811",
        label: "Caterers",
    },
    {
        code: "charitable_and_social_service_organizations_fundraising",
        value: "8398",
        label: "Charitable and Social Service Organizations - Fundraising",
    },
    {
        code: "chemicals_and_allied_products",
        value: "5169",
        label: "Chemicals and Allied Products (Not Elsewhere Classified)",
    },
    {
        code: "chidrens_and_infants_wear_stores",
        value: "5641",
        label: "Chidrens and Infants Wear Stores",
    },
    {
        code: "child_care_services",
        value: "8351",
        label: "Child Care Services",
    },
    {
        code: "chiropodists_podiatrists",
        value: "8049",
        label: "Chiropodists Podiatrists",
    },
    {
        code: "chiropractors",
        value: "8041",
        label: "Chiropractors",
    },
    {
        code: "cigar_stores_and_stands",
        value: "5993",
        label: "Cigar Stores and Stands",
    },
    {
        code: "civic_social_fraternal_associations",
        value: "8641",
        label: "Civic Social Fraternal Associations",
    },
    {
        code: "cleaning_and_maintenance",
        value: "7349",
        label: "Cleaning and Maintenance",
    },
    {
        code: "clothing_rental",
        value: "7296",
        label: "Clothing Rental",
    },
    {
        code: "colleges_universities",
        value: "8220",
        label: "Colleges Universities",
    },
    {
        code: "commercial_equipment",
        value: "5046",
        label: "Commercial Equipment (Not Elsewhere Classified)",
    },
    {
        code: "commercial_footwear",
        value: "5139",
        label: "Commercial Footwear",
    },
    {
        code: "commercial_photography_art_and_graphics",
        value: "7333",
        label: "Commercial Photography Art and Graphics",
    },
    {
        code: "commuter_transport_and_ferries",
        value: "4111",
        label: "Commuter Transport Ferries",
    },
    {
        code: "computer_network_services",
        value: "4816",
        label: "Computer Network Services",
    },
    {
        code: "computer_programming",
        value: "7372",
        label: "Computer Programming",
    },
    {
        code: "computer_repair",
        value: "7379",
        label: "Computer Repair",
    },
    {
        code: "computer_software_stores",
        value: "5734",
        label: "Computer Software Stores",
    },
    {
        code: "computers_peripherals_and_software",
        value: "5045",
        label: "Computers Peripherals and Software",
    },
    {
        code: "concrete_work_services",
        value: "1771",
        label: "Concrete Work Services",
    },
    {
        code: "construction_materials",
        value: "5039",
        label: "Construction Materials (Not Elsewhere Classified)",
    },
    {
        code: "consulting_public_relations",
        value: "7392",
        label: "Consulting Public Relations",
    },
    {
        code: "correspondence_schools",
        value: "8241",
        label: "Correspondence Schools",
    },
    {
        code: "cosmetic_stores",
        value: "5977",
        label: "Cosmetic Stores",
    },
    {
        code: "counseling_services",
        value: "7277",
        label: "Counseling Services",
    },
    {
        code: "country_clubs",
        value: "7997",
        label: "Country Clubs",
    },
    {
        code: "courier_services",
        value: "4215",
        label: "Courier Services",
    },
    {
        code: "court_costs",
        value: "9211",
        label: "Court Costs Including Alimony and Child Support - Courts of Law",
    },
    {
        code: "credit_reporting_agencies",
        value: "7321",
        label: "Credit Reporting Agencies",
    },
    {
        code: "cruise_lines",
        value: "4411",
        label: "Cruise Lines",
    },
    {
        code: "dairy_products_stores",
        value: "5451",
        label: "Dairy Products Stores",
    },
    {
        code: "dance_hall_studios_schools",
        value: "7911",
        label: "Dance Hall Studios Schools",
    },
    {
        code: "dating_escort_services",
        value: "7273",
        label: "Dating/Escort Services",
    },
    {
        code: "dentists_orthodontists",
        value: "8021",
        label: "Dentists Orthodontists",
    },
    {
        code: "department_stores",
        value: "5311",
        label: "Department Stores",
    },
    {
        code: "detective_agencies",
        value: "7393",
        label: "Detective Agencies",
    },
    {
        code: "direct_marketing_catalog_merchant",
        value: "5964",
        label: "Direct Marketing - Catalog Merchant",
    },
    {
        code: "direct_marketing_combination_catalog_and_retail_merchant",
        value: "5965",
        label: "Direct Marketing - Combination Catalog and Retail Merchant",
    },
    {
        code: "direct_marketing_inbound_telemarketing",
        value: "5967",
        label: "Direct Marketing - Inbound Telemarketing",
    },
    {
        code: "direct_marketing_insurance_services",
        value: "5960",
        label: "Direct Marketing - Insurance Services",
    },
    {
        code: "direct_marketing_other",
        value: "5969",
        label: "Direct Marketing - Other",
    },
    {
        code: "direct_marketing_outbound_telemarketing",
        value: "5966",
        label: "Direct Marketing - Outbound Telemarketing",
    },
    {
        code: "direct_marketing_subscription",
        value: "5968",
        label: "Direct Marketing - Subscription",
    },
    {
        code: "direct_marketing_travel",
        value: "5962",
        label: "Direct Marketing - Travel",
    },
    {
        code: "discount_stores",
        value: "5310",
        label: "Discount Stores",
    },
    {
        code: "doctors",
        value: "8011",
        label: "Doctors",
    },
    {
        code: "door_to_door_sales",
        value: "5963",
        label: "Door-To-Door Sales",
    },
    {
        code: "drapery_window_covering_and_upholstery_stores",
        value: "5714",
        label: "Drapery Window Covering and Upholstery Stores",
    },
    {
        code: "drinking_places",
        value: "5813",
        label: "Drinking Places",
    },
    {
        code: "drug_stores_and_pharmacies",
        value: "5912",
        label: "Drug Stores and Pharmacies",
    },
    {
        code: "drugs_drug_proprietaries_and_druggist_sundries",
        value: "5122",
        label: "Drugs Drug Proprietaries and Druggist Sundries",
    },
    {
        code: "dry_cleaners",
        value: "7216",
        label: "Dry Cleaners",
    },
    {
        code: "durable_goods",
        value: "5099",
        label: "Durable Goods (Not Elsewhere Classified)",
    },
    {
        code: "duty_free_stores",
        value: "5309",
        label: "Duty Free Stores",
    },
    {
        code: "eating_places_restaurants",
        value: "5812",
        label: "Eating Places Restaurants",
    },
    {
        code: "educational_services",
        value: "8299",
        label: "Educational Services",
    },
    {
        code: "electric_razor_stores",
        value: "5997",
        label: "Electric Razor Stores",
    },
    {
        code: "electrical_parts_and_equipment",
        value: "5065",
        label: "Electrical Parts and Equipment",
    },
    {
        code: "electrical_services",
        value: "1731",
        label: "Electrical Services",
    },
    {
        code: "electronics_repair_shops",
        value: "7622",
        label: "Electronics Repair Shops",
    },
    {
        code: "electronics_stores",
        value: "5732",
        label: "Electronics Stores",
    },
    {
        code: "elementary_secondary_schools",
        value: "8211",
        label: "Elementary Secondary Schools",
    },
    {
        code: "employment_temp_agencies",
        value: "7361",
        label: "Employment/Temp Agencies",
    },
    {
        code: "equipment_rental",
        value: "7394",
        label: "Equipment Rental",
    },
    {
        code: "exterminating_services",
        value: "7342",
        label: "Exterminating Services",
    },
    {
        code: "family_clothing_stores",
        value: "5651",
        label: "Family Clothing Stores",
    },
    {
        code: "fast_food_restaurants",
        value: "5814",
        label: "Fast Food Restaurants",
    },
    {
        code: "financial_institutions",
        value: "6012",
        label: "Financial Institutions",
    },
    {
        code: "fines_government_administrative_entities",
        value: "9222",
        label: "Fines - Government Administrative Entities",
    },
    {
        code: "fireplace_fireplace_screens_and_accessories_stores",
        value: "5718",
        label: "Fireplace Fireplace Screens and Accessories Stores",
    },
    {
        code: "floor_covering_stores",
        value: "5713",
        label: "Floor Covering Stores",
    },
    {
        code: "florists",
        value: "5992",
        label: "Florists",
    },
    {
        code: "florists_supplies_nursery_stock_and_flowers",
        value: "5193",
        label: "Florists Supplies Nursery Stock and Flowers",
    },
    {
        code: "freezer_and_locker_meat_provisioners",
        value: "5422",
        label: "Freezer and Locker Meat Provisioners",
    },
    {
        code: "fuel_dealers_non_automotive",
        value: "5983",
        label: "Fuel Dealers (Non Automotive)",
    },
    {
        code: "funeral_services_crematories",
        value: "7261",
        label: "Funeral Services Crematories",
    },
    {
        code: "furniture_repair_refinishing",
        value: "7641",
        label: "Furniture Repair Refinishing",
    },
    {
        code: "furniture_home_furnishings_and_equipment_stores_except_appliances",
        value: "5712",
        label: "Furniture Home Furnishings and Equipment Stores Except Appliances",
    },
    {
        code: "furriers_and_fur_shops",
        value: "5681",
        label: "Furriers and Fur Shops",
    },
    {
        code: "general_services",
        value: "1520",
        label: "General Services",
    },
    {
        code: "gift_card_novelty_and_souvenir_shops",
        value: "5947",
        label: "Gift Card Novelty and Souvenir Shops",
    },
    {
        code: "glass_paint_and_wallpaper_stores",
        value: "5231",
        label: "Glass Paint and Wallpaper Stores",
    },
    {
        code: "glassware_crystal_stores",
        value: "5950",
        label: "Glassware Crystal Stores",
    },
    {
        code: "golf_courses_public",
        value: "7992",
        label: "Golf Courses - Public",
    },
    {
        code: "government_services",
        value: "9399",
        label: "Government Services (Not Elsewhere Classified)",
    },
    {
        code: "grocery_stores_supermarkets",
        value: "5411",
        label: "Grocery Stores Supermarkets",
    },
    {
        code: "hardware_stores",
        value: "5251",
        label: "Hardware Stores",
    },
    {
        code: "hardware_equipment_and_supplies",
        value: "5072",
        label: "Hardware Equipment and Supplies",
    },
    {
        code: "health_and_beauty_spas",
        value: "7298",
        label: "Health and Beauty Spas",
    },
    {
        code: "hearing_aids_sales_and_supplies",
        value: "5975",
        label: "Hearing Aids Sales and Supplies",
    },
    {
        code: "heating_plumbing_a_c",
        value: "1711",
        label: "Heating Plumbing A/C",
    },
    {
        code: "hobby_toy_and_game_shops",
        value: "5945",
        label: "Hobby Toy and Game Shops",
    },
    {
        code: "home_supply_warehouse_stores",
        value: "5200",
        label: "Home Supply Warehouse Stores",
    },
    {
        code: "hospitals",
        value: "8062",
        label: "Hospitals",
    },
    {
        code: "hotels_motels_and_resorts",
        value: "7011",
        label: "Hotels Motels and Resorts",
    },
    {
        code: "household_appliance_stores",
        value: "5722",
        label: "Household Appliance Stores",
    },
    {
        code: "industrial_supplies",
        value: "5085",
        label: "Industrial Supplies (Not Elsewhere Classified)",
    },
    {
        code: "information_retrieval_services",
        value: "7375",
        label: "Information Retrieval Services",
    },
    {
        code: "insurance_default",
        value: "6399",
        label: "Insurance - Default",
    },
    {
        code: "insurance_underwriting_premiums",
        value: "6300",
        label: "Insurance Underwriting Premiums",
    },
    {
        code: "intra_company_purchases",
        value: "9950",
        label: "Intra-Company Purchases",
    },
    {
        code: "jewelry_stores_watches_clocks_and_silverware_stores",
        value: "5944",
        label: "Jewelry Stores Watches Clocks and Silverware Stores",
    },
    {
        code: "landscaping_services",
        value: "0780",
        label: "Landscaping Services",
    },
    {
        code: "laundries",
        value: "7211",
        label: "Laundries",
    },
    {
        code: "laundry_cleaning_services",
        value: "7210",
        label: "Laundry Cleaning Services",
    },
    {
        code: "legal_services_attorneys",
        value: "8111",
        label: "Legal Services Attorneys",
    },
    {
        code: "luggage_and_leather_goods_stores",
        value: "5948",
        label: "Luggage and Leather Goods Stores",
    },
    {
        code: "lumber_building_materials_stores",
        value: "5211",
        label: "Lumber Building Materials Stores",
    },
    {
        code: "manual_cash_disburse",
        value: "6010",
        label: "Manual Cash Disburse",
    },
    {
        code: "marinas_service_and_supplies",
        value: "4468",
        label: "Marinas Service and Supplies",
    },
    {
        code: "masonry_stonework_and_plaster",
        value: "1740",
        label: "Masonry Stonework and Plaster",
    },
    {
        code: "massage_parlors",
        value: "7297",
        label: "Massage Parlors",
    },
    {
        code: "medical_services",
        value: "8099",
        label: "Medical Services",
    },
    {
        code: "medical_and_dental_labs",
        value: "8071",
        label: "Medical and Dental Labs",
    },
    {
        code: "medical_dental_ophthalmic_and_hospital_equipment_and_supplies",
        value: "5047",
        label: "Medical Dental Ophthalmic and Hospital Equipment and Supplies",
    },
    {
        code: "membership_organizations",
        value: "8699",
        label: "Membership Organizations",
    },
    {
        code: "mens_and_boys_clothing_and_accessories_stores",
        value: "5611",
        label: "Mens and Boys Clothing and Accessories Stores",
    },
    {
        code: "mens_womens_clothing_stores",
        value: "5691",
        label: "Mens Womens Clothing Stores",
    },
    {
        code: "metal_service_centers",
        value: "5051",
        label: "Metal Service Centers",
    },
    {
        code: "miscellaneous_apparel_and_accessory_shops",
        value: "5699",
        label: "Miscellaneous Apparel and Accessory Shops",
    },
    {
        code: "miscellaneous_auto_dealers",
        value: "5599",
        label: "Miscellaneous Auto Dealers",
    },
    {
        code: "miscellaneous_business_services",
        value: "7399",
        label: "Miscellaneous Business Services",
    },
    {
        code: "miscellaneous_food_stores",
        value: "5499",
        label: "Miscellaneous Food Stores - Convenience Stores and Specialty Markets",
    },
    {
        code: "miscellaneous_general_merchandise",
        value: "5399",
        label: "Miscellaneous General Merchandise",
    },
    {
        code: "miscellaneous_general_services",
        value: "7299",
        label: "Miscellaneous General Services",
    },
    {
        code: "miscellaneous_home_furnishing_specialty_stores",
        value: "5719",
        label: "Miscellaneous Home Furnishing Specialty Stores",
    },
    {
        code: "miscellaneous_publishing_and_printing",
        value: "2741",
        label: "Miscellaneous Publishing and Printing",
    },
    {
        code: "miscellaneous_recreation_services",
        value: "7999",
        label: "Miscellaneous Recreation Services",
    },
    {
        code: "miscellaneous_repair_shops",
        value: "7699",
        label: "Miscellaneous Repair Shops",
    },
    {
        code: "miscellaneous_specialty_retail",
        value: "5999",
        label: "Miscellaneous Specialty Retail",
    },
    {
        code: "mobile_home_dealers",
        value: "5271",
        label: "Mobile Home Dealers",
    },
    {
        code: "motion_picture_theaters",
        value: "7832",
        label: "Motion Picture Theaters",
    },
    {
        code: "motor_freight_carriers_and_trucking",
        value: "4214",
        label: "Motor Freight Carriers and Trucking - Local and Long Distance Moving and Storage Companies and Local Delivery Services",
    },
    {
        code: "motor_homes_dealers",
        value: "5592",
        label: "Motor Homes Dealers",
    },
    {
        code: "motor_vehicle_supplies_and_new_parts",
        value: "5013",
        label: "Motor Vehicle Supplies and New Parts",
    },
    {
        code: "motorcycle_shops_and_dealers",
        value: "5571",
        label: "Motorcycle Shops and Dealers",
    },
    {
        code: "motorcycle_shops_dealers",
        value: "5561",
        label: "Motorcycle Shops Dealers",
    },
    {
        code: "music_stores_musical_instruments_pianos_and_sheet_music",
        value: "5733",
        label: "Music Stores-Musical Instruments Pianos and Sheet Music",
    },
    {
        code: "news_dealers_and_newsstands",
        value: "5994",
        label: "News Dealers and Newsstands",
    },
    {
        code: "non_fi_money_orders",
        value: "6051",
        label: "Non-FI Money Orders",
    },
    {
        code: "non_fi_stored_value_card_purchase_load",
        value: "6540",
        label: "Non-FI Stored Value Card Purchase/Load",
    },
    {
        code: "nondurable_goods",
        value: "5199",
        label: "Nondurable Goods (Not Elsewhere Classified)",
    },
    {
        code: "nurseries_lawn_and_garden_supply_stores",
        value: "5261",
        label: "Nurseries Lawn and Garden Supply Stores",
    },
    {
        code: "nursing_personal_care",
        value: "8050",
        label: "Nursing/Personal Care",
    },
    {
        code: "office_and_commercial_furniture",
        value: "5021",
        label: "Office and Commercial Furniture",
    },
    {
        code: "opticians_eyeglasses",
        value: "8043",
        label: "Opticians Eyeglasses",
    },
    {
        code: "optometrists_ophthalmologist",
        value: "8042",
        label: "Optometrists Ophthalmologist",
    },
    {
        code: "orthopedic_goods_prosthetic_devices",
        value: "5976",
        label: "Orthopedic Goods - Prosthetic Devices",
    },
    {
        code: "osteopaths",
        value: "8031",
        label: "Osteopaths",
    },
    {
        code: "package_stores_beer_wine_and_liquor",
        value: "5921",
        label: "Package Stores-Beer Wine and Liquor",
    },
    {
        code: "paints_varnishes_and_supplies",
        value: "5198",
        label: "Paints Varnishes and Supplies",
    },
    {
        code: "parking_lots_garages",
        value: "7523",
        label: "Parking Lots Garages",
    },
    {
        code: "passenger_railways",
        value: "4112",
        label: "Passenger Railways",
    },
    {
        code: "pawn_shops",
        value: "5933",
        label: "Pawn Shops",
    },
    {
        code: "pet_shops_pet_food_and_supplies",
        value: "5995",
        label: "Pet Shops Pet Food and Supplies",
    },
    {
        code: "petroleum_and_petroleum_products",
        value: "5172",
        label: "Petroleum and Petroleum Products",
    },
    {
        code: "photo_developing",
        value: "7395",
        label: "Photo Developing",
    },
    {
        code: "photographic_studios",
        value: "7221",
        label: "Photographic Studios",
    },
    {
        code: "photographic_photocopy_microfilm_equipment_and_supplies",
        value: "5044",
        label: "Photographic Photocopy Microfilm Equipment and Supplies",
    },
    {
        code: "picture_video_production",
        value: "7829",
        label: "Picture/Video Production",
    },
    {
        code: "piece_goods_notions_and_other_dry_goods",
        value: "5131",
        label: "Piece Goods Notions and Other Dry Goods",
    },
    {
        code: "plumbing_heating_equipment_and_supplies",
        value: "5074",
        label: "Plumbing Heating Equipment and Supplies",
    },
    {
        code: "political_organizations",
        value: "8651",
        label: "Political Organizations",
    },
    {
        code: "postal_services_government_only",
        value: "9402",
        label: "Postal Services - Government Only",
    },
    {
        code: "precious_stones_and_metals_watches_and_jewelry",
        value: "5094",
        label: "Precious Stones and Metals Watches and Jewelry",
    },
    {
        code: "professional_services",
        value: "8999",
        label: "Professional Services",
    },
    {
        code: "public_warehousing_and_storage",
        value: "4225",
        label: "Public Warehousing and Storage - Farm Products Refrigerated Goods Household Goods and Storage",
    },
    {
        code: "quick_copy_repro_and_blueprint",
        value: "7338",
        label: "Quick Copy Repro and Blueprint",
    },
    {
        code: "railroads",
        value: "4011",
        label: "Railroads",
    },
    {
        code: "real_estate_agents_and_managers_rentals",
        value: "6513",
        label: "Real Estate Agents and Managers - Rentals",
    },
    {
        code: "record_stores",
        value: "5735",
        label: "Record Stores",
    },
    {
        code: "recreational_vehicle_rentals",
        value: "7519",
        label: "Recreational Vehicle Rentals",
    },
    {
        code: "religious_goods_stores",
        value: "5973",
        label: "Religious Goods Stores",
    },
    {
        code: "religious_organizations",
        value: "8661",
        label: "Religious Organizations",
    },
    {
        code: "roofing_siding_sheet_metal",
        value: "1761",
        label: "Roofing/Siding Sheet Metal",
    },
    {
        code: "secretarial_support_services",
        value: "7339",
        label: "Secretarial Support Services",
    },
    {
        code: "security_brokers_dealers",
        value: "6211",
        label: "Security Brokers/Dealers",
    },
    {
        code: "service_stations",
        value: "5541",
        label: "Service Stations",
    },
    {
        code: "sewing_needlework_fabric_and_piece_goods_stores",
        value: "5949",
        label: "Sewing Needlework Fabric and Piece Goods Stores",
    },
    {
        code: "shoe_repair_hat_cleaning",
        value: "7251",
        label: "Shoe Repair/Hat Cleaning",
    },
    {
        code: "shoe_stores",
        value: "5661",
        label: "Shoe Stores",
    },
    {
        code: "small_appliance_repair",
        value: "7629",
        label: "Small Appliance Repair",
    },
    {
        code: "snowmobile_dealers",
        value: "5598",
        label: "Snowmobile Dealers",
    },
    {
        code: "special_trade_services",
        value: "1799",
        label: "Special Trade Services",
    },
    {
        code: "specialty_cleaning",
        value: "2842",
        label: "Specialty Cleaning",
    },
    {
        code: "sporting_goods_stores",
        value: "5941",
        label: "Sporting Goods Stores",
    },
    {
        code: "sporting_recreation_camps",
        value: "7032",
        label: "Sporting/Recreation Camps",
    },
    {
        code: "sports_clubs_fields",
        value: "7941",
        label: "Sports Clubs/Fields",
    },
    {
        code: "sports_and_riding_apparel_stores",
        value: "5655",
        label: "Sports and Riding Apparel Stores",
    },
    {
        code: "stamp_and_coin_stores",
        value: "5972",
        label: "Stamp and Coin Stores",
    },
    {
        code: "stationary_office_supplies_printing_and_writing_paper",
        value: "5111",
        label: "Stationary Office Supplies Printing and Writing Paper",
    },
    {
        code: "stationery_stores_office_and_school_supply_stores",
        value: "5943",
        label: "Stationery Stores Office and School Supply Stores",
    },
    {
        code: "swimming_pools_sales",
        value: "5996",
        label: "Swimming Pools Sales",
    },
    {
        code: "t_ui_travel_germany",
        value: "4723",
        label: "TUI Travel - Germany",
    },
    {
        code: "tailors_alterations",
        value: "5697",
        label: "Tailors Alterations",
    },
    {
        code: "tax_payments_government_agencies",
        value: "9311",
        label: "Tax Payments - Government Agencies",
    },
    {
        code: "tax_preparation_services",
        value: "7276",
        label: "Tax Preparation Services",
    },
    {
        code: "taxicabs_limousines",
        value: "4121",
        label: "Taxicabs/Limousines",
    },
    {
        code: "telecommunication_equipment_and_telephone_sales",
        value: "4812",
        label: "Telecommunication Equipment and Telephone Sales",
    },
    {
        code: "telecommunication_services",
        value: "4814",
        label: "Telecommunication Services",
    },
    {
        code: "telegraph_services",
        value: "4821",
        label: "Telegraph Services",
    },
    {
        code: "tent_and_awning_shops",
        value: "5998",
        label: "Tent and Awning Shops",
    },
    {
        code: "testing_laboratories",
        value: "8734",
        label: "Testing Laboratories",
    },
    {
        code: "theatrical_ticket_agencies",
        value: "7922",
        label: "Theatrical Ticket Agencies",
    },
    {
        code: "timeshares",
        value: "7012",
        label: "Timeshares",
    },
    {
        code: "tire_retreading_and_repair",
        value: "7534",
        label: "Tire Retreading and Repair",
    },
    {
        code: "tolls_bridge_fees",
        value: "4784",
        label: "Tolls/Bridge Fees",
    },
    {
        code: "tourist_attractions_and_exhibits",
        value: "7991",
        label: "Tourist Attractions and Exhibits",
    },
    {
        code: "towing_services",
        value: "7549",
        label: "Towing Services",
    },
    {
        code: "trailer_parks_campgrounds",
        value: "7033",
        label: "Trailer Parks Campgrounds",
    },
    {
        code: "transportation_services",
        value: "4789",
        label: "Transportation Services (Not Elsewhere Classified)",
    },
    {
        code: "travel_agencies_tour_operators",
        value: "4722",
        label: "Travel Agencies Tour Operators",
    },
    {
        code: "truck_stop_iteration",
        value: "7511",
        label: "Truck StopIteration",
    },
    {
        code: "truck_utility_trailer_rentals",
        value: "7513",
        label: "Truck/Utility Trailer Rentals",
    },
    {
        code: "typesetting_plate_making_and_related_services",
        value: "2791",
        label: "Typesetting Plate Making and Related Services",
    },
    {
        code: "typewriter_stores",
        value: "5978",
        label: "Typewriter Stores",
    },
    {
        code: "u_s_federal_government_agencies_or_departments",
        value: "9405",
        label: "U.S. Federal Government Agencies or Departments",
    },
    {
        code: "uniforms_commercial_clothing",
        value: "5137",
        label: "Uniforms Commercial Clothing",
    },
    {
        code: "used_merchandise_and_secondhand_stores",
        value: "5931",
        label: "Used Merchandise and Secondhand Stores",
    },
    {
        code: "utilities",
        value: "4900",
        label: "Utilities",
    },
    {
        code: "variety_stores",
        value: "5331",
        label: "Variety Stores",
    },
    {
        code: "veterinary_services",
        value: "0742",
        label: "Veterinary Services",
    },
    {
        code: "video_amusement_game_supplies",
        value: "7993",
        label: "Video Amusement Game Supplies",
    },
    {
        code: "video_game_arcades",
        value: "7994",
        label: "Video Game Arcades",
    },
    {
        code: "video_tape_rental_stores",
        value: "7841",
        label: "Video Tape Rental Stores",
    },
    {
        code: "vocational_trade_schools",
        value: "8249",
        label: "Vocational/Trade Schools",
    },
    {
        code: "watch_jewelry_repair",
        value: "7631",
        label: "Watch/Jewelry Repair",
    },
    {
        code: "welding_repair",
        value: "7692",
        label: "Welding Repair",
    },
    {
        code: "wholesale_clubs",
        value: "5300",
        label: "Wholesale Clubs",
    },
    {
        code: "wig_and_toupee_stores",
        value: "5698",
        label: "Wig and Toupee Stores",
    },
    {
        code: "wires_money_orders",
        value: "4829",
        label: "Wires Money Orders",
    },
    {
        code: "womens_accessory_and_specialty_shops",
        value: "5631",
        label: "Womens Accessory and Specialty Shops",
    },
    {
        code: "womens_ready_to_wear_stores",
        value: "5621",
        label: "Womens Ready-To-Wear Stores",
    },
    {
        code: "wrecking_and_salvage_yards",
        value: "5935",
        label: "Wrecking and Salvage Yards",
    },
];